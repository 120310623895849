import React, { useEffect, useState } from "react";
import { useMultipleSelection, useCombobox } from "downshift";
import classNames from "classnames/bind";
import { Icon } from "../icon";

const cx = classNames.bind();

export const Select = ({
  selected = [],
  label,
  items,
  onChange,
  singleMode = false,
  compact = false,
  className = "",
  ...rest
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedItems, setSelectedItems] = useState(selected);

  useEffect(() => {
    if (JSON.stringify(selected) !== JSON.stringify(selectedItems)) {
      setSelectedItems(selected);
    }
  }, [selectedItems, onChange, selected]);

  const getItemLabel = (item) =>
    typeof item === "object" && item !== null ? item.label : item;

  const isItemSelected = (item) =>
    selectedItems.some((selectedItem) =>
      typeof item === "object"
        ? selectedItem.value === item.value
        : selectedItem === item,
    );

  const { getDropdownProps } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          setSelectedItems(newSelectedItems);
          break;
        default:
          break;
      }
    },
  });

  const {
    isOpen,
    getLabelProps,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items,
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur: {
          if (!newSelectedItem) return;

          if (singleMode) {
            const newSelected = selectedItems.includes(newSelectedItem)
              ? []
              : [newSelectedItem];
            setSelectedItems(newSelected);
            return onChange(newSelected);
          }

          const newSelectedItems = selectedItems.includes(newSelectedItem)
            ? selectedItems.filter((item) =>
              typeof item === "object"
                ? item.value !== newSelectedItem.value
                : item !== newSelectedItem,
            )
            : Array.from(new Set([...selectedItems, newSelectedItem]));

          setSelectedItems(newSelectedItems);
          onChange(newSelectedItems);
          return setInputValue("");
        }
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue);
          break;
        default:
          break;
      }
    },
  });

  return (
    <div {...rest} className={`${className} relative`}>
      <div className="flex flex-col gap-1">
        <label {...getLabelProps()} className="text-[13px] text-[#282F44]">
          {label}
        </label>
        <div
          className={`border border-[#C6CCE8] hover:border-[#A1A7C2] rounded-lg border-2 mr-[8px] bg-white inline-flex gap-4 items-center flex-wrap ${compact ? "text-xs px-x-small" : "text-base px-medium py-regular"}`}
        >
          <div className="flex gap-0.5 grow">
            <input
              placeholder={
                selectedItems.length
                  ? selectedItems.map(getItemLabel).join(", ")
                  : "Select one or more"
              }
              className="w-full placeholder-shown:text-ellipsis outline-none placeholder:text-[#282F44] placeholder:focus:text-[#C6CCE8]"
              {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
            />
            {Boolean(selectedItems.length) && !singleMode && (
              <button
                onClick={() => {
                  setSelectedItems([]);
                  onChange([]);
                }}
              >
                <Icon glyphName="close" className="text-slate-600" />
              </button>
            )}
            <button
              aria-label="toggle menu"
              type="button"
              {...getToggleButtonProps()}
            >
              <Icon
                glyphName={isOpen ? "expand_less" : "expand_more"}
                className={`text-slate-600 ${compact ? "!text-sm" : "!text-base"}`}
              />
            </button>
          </div>
        </div>
      </div>
      <ul
        className={`w-full absolute bg-white mt-1 max-h-80 overflow-x-scroll p-[8px] z-10 rounded-lg shadow-[0_8px_8px_-4px_rgba(0,0,0,0.08),_0px_0px_8px_2px_rgba(0,0,0,0.08)] no-scrollbar ${!(isOpen && items.length) && "hidden"
          }`}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => {
            const itemLabel = getItemLabel(item);
            const itemIsSelected = isItemSelected(item);
            const itemMatchesSearch = inputValue?.length
              ? itemLabel.toLowerCase().includes(inputValue.toLowerCase())
              : true;

            return (
              <li
                className={cx(
                  highlightedIndex === index && "bg-[#EFF6FF]",
                  itemIsSelected && "bg-[#EFF6FF]",
                  !itemMatchesSearch && "hidden",
                  "py-[8px] px-[6px] mb-[4px] flex flex-col rounded-md text-[#282F44]",
                )}
                key={typeof item === "object" ? item.value : item}
                {...getItemProps({ item, index })}
              >
                <span className="text-sm text-gray-700 flex justify-between">
                  <span>{itemLabel}</span>{" "}
                  <span>
                    {itemIsSelected && (
                      <Icon
                        glyphName="check"
                        className={`text-slate-800 ${compact ? "!text-sm" : "!text-base"}`}
                      />
                    )}
                  </span>
                </span>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
