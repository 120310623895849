import * as React from "react";

export const ProductIcon = ({ icon }) => {
  return (
    <div className="bg-blue-700 text-white w-[24px] h-[24px] rounded flex items-center justify-center">
      <i className="material-symbols-rounded">
        <span className="text-[20px] -top-[1px] relative inline-block">
          {icon}
        </span>
      </i>
    </div>
  );
};
