import React from "react";

import "material-symbols";

export const Icon = ({ outlined = false, glyphName, size="1rem", className }) => {
  return (
    <i
      className={`material-symbols-${outlined ? "outlined" : "rounded"} 
      text-[${size}] text-[#282F44] 
      flex justify-center items-center 
      relative top-[1px] ${className}`}
    >
      {glyphName}
    </i>
  );
};
