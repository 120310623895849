import React, { useContext } from "react";

import { Status, StatusDot } from "../components/fixtures";
import { Button, Icon } from "../shared-components";

import { ProductAnalysisContext } from "./ProductAnalysisProvider";

export const ProductOutcome = () => {
  const { productInfo, reset } = useContext(ProductAnalysisContext);

  const [product] = productInfo?.products_found ?? [];

  console.log({ productInfo });

  return (
    <>
      <div className="product-analysis-column-header">
        <StatusDot status={Status.default} />
        <div className="uppercase">3. Outcome</div>
      </div>
      {product ? (
        <div className="flex items-center justify-center h-full">
          <div className="flex flex-col gap-2 items-center">
            <h3 className="text-4xl">Place in Bin A</h3>
            <p>This product has been identified for refill</p>
            <Button
              iconGlyph="arrow_forward"
              label="Next Item"
              onClick={() => reset()}
            />
          </div>
        </div>
      ) : (
        <div className="product-analysis-column-content">
          <div className="flex flex-col h-full w-full justify-center items-center">
            <Icon
              glyphName="copy_all"
              className="text-blue text-[8rem] h-[8rem]"
            />
            <p className="text-blue">Product Outcome</p>
          </div>
        </div>
      )}
    </>
  );
};
