import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Icon } from "../icon";

const getValueFromAccessor = ({ data, accessor }) => {
  return accessor.split(".").reduce((o, i) => o[i], data);
};

const SortArrow = ({ direction }) => {
  if (direction === "asc") {
    return (
      <Icon
        glyphName="expand_less"
        className={`!text-sm !absolute -right-4 -top-1`}
      />
    );
  }

  if (direction === "desc") {
    return (
      <Icon
        glyphName="expand_more"
        className={`!text-sm !absolute -right-4 -top-1`}
      />
    );
  }

  return null;
};

export const Table = ({ columns, data, onRowClick }) => {
  const [sortConfig, setSortConfig] = useState(null);

  const sortedData = useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = getValueFromAccessor({
          data: a,
          accessor: sortConfig.key,
        });
        const bValue = getValueFromAccessor({
          data: b,
          accessor: sortConfig.key,
        });

        return aValue < bValue
          ? sortConfig.direction === "asc"
            ? -1
            : 1
          : aValue > bValue
            ? sortConfig.direction === "asc"
              ? 1
              : -1
            : 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="overflow-x-auto">
      <div className="md:flex hidden font-semibold px-[24px] py-[12px] border-b border-blue-50">
        {columns.map((column) => (
          <button
            key={column.accessor}
            className={`uppercase w-full px-[4px] text-left outline-none flex text-xs ${column.maxContentWidth ? "whitespace-nowrap" : ""}`}
            style={column.maxContentWidth ? { maxWidth: "fit-content" } : {}}
            onClick={() => requestSort(column.accessor)}
          >
            <span className="relative">
              {column.Header}
              {column.isSortable && sortConfig?.key === column.accessor && (
                <SortArrow direction={sortConfig?.direction} />
              )}
            </span>
          </button>
        ))}
      </div>

      {sortedData.map((row, index) => (
        <div
          key={index}
          className={`
            flex md:flex-row flex-col 
            px-[24px] 
            border-b border-blue-50 w-full 
            focus:outline-none 
            focus:ring-2 focus:ring-blue-600 hover:bg-slate-50 
            items-center text-base
         `}
        >
          {columns.map((column) => (
            <button
              key={column.accessor}
              className={`w-full px-[4px] py-[12px] text-left ${column.maxContentWidth ? "whitespace-nowrap" : ""}`}
              style={column.maxContentWidth ? { maxWidth: "fit-content" } : {}}
              onClick={() => !column.isClickable && onRowClick({ row })}
            >
              <span className="md:hidden font-semibold">{column.Header}:</span>
              {column.Cell
                ? column.Cell({ row })
                : getValueFromAccessor({
                  accessor: column.accessor,
                  data: row,
                })}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      accessor: PropTypes.string.isRequired,
      isSortable: PropTypes.bool,
      Cell: PropTypes.func,
      maxContentWidth: PropTypes.bool, // New property
    }),
  ).isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
};

Table.defaultProps = {
  onRowClick: () => { },
};
