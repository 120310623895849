import * as React from "react";
import * as Popover from "@radix-ui/react-popover";
import { ProductIcon } from "../product-icon";

import Logo from "./logo.svg";

import "material-symbols";

const apps = {
  Projects: {
    url: {
      dev: `https://app.pentatonic.com/dev`,
      staging: `https://app.pentatonic.com/training`,
      demo: `https://app.pentatonic.com/demo`,
      production: `https://app.pentatonic.com`,
    },
    icon: `rocket_launch`,
    position: 1,
  },
  "Circularity Calculator": {
    url: {
      dev: `https://circularity-calculator.pentatonic.com/dev`,
      staging: `https://circularity-calculator.pentatonic.com/training`,
      demo: `https://circularity-calculator.pentatonic.com/demo`,
      production: `https://circularity-calculator.pentatonic.com`,
    },
    icon: `calculate`,
    position: 2,
  },
  "Materials Sourcing": {
    url: {
      dev: `https://material-sourcing-dev.pentatonic.com`,
      staging: `https://material-sourcing-demo.pentatonic.com`,
      demo: `https://material-sourcing-demo.pentatonic.com`,
      production: `https://material-sourcing.pentatonic.com`,
    },
    icon: `conveyor_belt`,
    position: 3,
  },
  "Take-back Program": {
    url: {
      dev: `https://decommissioning-dev.pentatonic.com`,
      staging: `https://decommissioning-dev.pentatonic.com`,
      demo: `https://decommissioning-demo.pentatonic.com`,
      production: `https://decommissioning.pentatonic.com`,
    },
    icon: `box`,
    position: 4,
  },
  "Legislation Tracker": {
    url: {
      dev: `https://legislation-dev.pentatonic.com`,
      staging: `https://legislation-dev.pentatonic.com`,
      demo: `https://legislation-demo.pentatonic.com`,
      production: `https://legislation.pentatonic.com`,
    },
    icon: `other_admission`,
    position: 5,
  },
};

export const MainNav = ({
  hideSignIn,
  user,
  AvatarMenuSlot,
  handleSignIn = () => { },
  handleSignOut = () => { },
  appName,
  env = `production`,
  showAppMenu = true,
}) => {
  return (
    <div className="flex px-6 py-4 items-center justify-center gap-4 border-b border-blue-50">
      {user?.orgId && (
        <Popover.Root>
          <Popover.Trigger>
            <img
              src={`${process.env.REACT_APP_URI_BASE_PATH}/assets/organizations/${user.orgId}/logo.png`}
              alt={user.orgNiceName}
              className="w-8 rounded"
            />
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className="rounded shadow-3xl p-2 bg-white min-w-[12rem] z-20"
              collisionPadding={16}
              sideOffset={8}
            >
              <ul className="flex flex-col gap-1">
                <li className="text-sm p-2 text-stone-800 font-medium">
                  Workspaces
                </li>
                {user.siblingOrgs.map(({ id: orgId, name, niceName }) => (
                  <li key={orgId}>
                    <button
                      onClick={() =>
                        window.location.assign(
                          `https://${name}.pentatonic.com${process.env.REACT_APP_URI_BASE_PATH}`,
                        )
                      }
                      className={`flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium ${orgId === user.orgId ? `bg-stone-50` : ``
                        }`}
                    >
                      <img
                        src={`/assets/organizations/${orgId}/logo.png`}
                        alt={niceName}
                        className="w-4 h-4 rounded"
                      />
                      <div
                        className={orgId === user.orgId ? `font-medium` : ``}
                      >
                        {niceName}
                      </div>
                      {orgId === user.orgId && (
                        <i className="material-symbols-rounded text-xl flex grow justify-end leading-none">
                          check
                        </i>
                      )}
                    </button>
                  </li>
                ))}
                {user.isInternal && (
                  <li>
                    <button
                      onClick={handleSignOut}
                      className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-400 rounded-sm hover:bg-stone-50 hover:font-medium"
                    >
                      Switch organisation
                    </button>
                  </li>
                )}
              </ul>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      )}
      <img src={Logo} alt="Pentatonic logo" className="w-[10rem]" />
      {user && showAppMenu && (
        <Popover.Root>
          <Popover.Trigger asChild>
            <button className="flex">
              <i className="material-symbols-rounded text-xxl leading-none">
                apps
              </i>
            </button>
          </Popover.Trigger>
          <Popover.Portal>
            <Popover.Content
              className="rounded shadow-3xl p-2 bg-white min-w-[12rem] z-20"
              collisionPadding={16}
              sideOffset={8}
            >
              <ul className="flex flex-col gap-1">
                <li className="text-sm p-2 text-stone-800 font-medium">
                  Switch to
                </li>
                {Object.entries(apps)
                  .sort(([, { position: a }], [, { position: b }]) =>
                    a > b ? 1 : -1,
                  )
                  .map(([key, { icon, url }]) => (
                    <li key={key}>
                      <a
                        href={url[env]}
                        className={`flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm cursor-pointer min-w-[18rem] hover:bg-stone-50 hover:font-medium group ${key === appName && `bg-stone-50`
                          }`}
                      >
                        <ProductIcon icon={icon} />
                        <div className="font-medium">{key}</div>
                      </a>
                    </li>
                  ))}
              </ul>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      )}
      {apps[appName] && (
        <div className="flex gap-2 items-center">
          <ProductIcon icon={apps[appName].icon} />
          <div className="hidden md:block">{appName}</div>
        </div>
      )}
      <div className="ml-auto">
        {user?.name ? (
          <Popover.Root>
            <Popover.Trigger className="flex gap-2 text-sm items-center">
              <div className="relative flex">
                <img
                  src={user.avatar}
                  alt={user.name}
                  className="w-6 h-6 rounded-full"
                />
                {Boolean(user?.notifications?.length) && (
                  <div className="w-2.5 h-2.5 bg-red-600 rounded-full border border-solid border-white absolute right-[-1px] top-[-1px]" />
                )}
              </div>
              <div>{user.name}</div>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                className="rounded shadow-3xl p-2 bg-white min-w-[16rem] z-20"
                collisionPadding={16}
                sideOffset={8}
                align="start"
              >
                <ul>
                  <li className="text-sm p-2 text-stone-800 font-medium">
                    {user.email}
                  </li>
                  {AvatarMenuSlot}
                  <li>
                    <button
                      onClick={handleSignOut}
                      className="flex p-2 w-full items-center gap-3 text-left text-sm text-stone-800 rounded-sm hover:bg-stone-50 hover:font-medium"
                    >
                      Log out
                    </button>
                  </li>
                </ul>
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        ) : (
          !hideSignIn && (
            <button
              className="bg-blue-700 rounded-lg px-4 py-2 text-white text-sm font-medium flex gap-3 items-center justify-center min-h-[2.25rem] hover:bg-blue-800 disabled:bg-stone-200"
              onClick={handleSignIn}
            >
              <i className="material-symbols-rounded text-xl flex grow justify-end leading-none">
                person_outline
              </i>
              <div>Sign in</div>
            </button>
          )
        )}
      </div>
    </div>
  );
};
