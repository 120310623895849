import React, { useContext, useEffect, useState } from "react";

import { Status, StatusDot } from "../components/fixtures";
import { ImageUploadForm } from "../components/form/ImageUploadForm";
import { Icon } from "../shared-components";

import { ProductAnalysisContext } from "./ProductAnalysisProvider";

export const ProductIdentification = () => {
  const { isIdle, isStarted, setProductInfo } = useContext(
    ProductAnalysisContext
  );
  const [image, setImage] = useState(null);

  const onImageCaptured = (imageObj) => {
    setImage(imageObj);
  };

  useEffect(() => {
    if (isIdle) {
      setImage(null);
    }
  }, [isIdle]);

  return (
    <>
      <div className="product-analysis-column-header">
        <StatusDot status={Status.default} />
        <div className="uppercase">1. Identification</div>
      </div>
      <div className="product-analysis-column-content justify-center w-full">
        {!isIdle && image && (
          <div className="flex items-center">
            <img src={image} alt="Preview" className="w-full object-cover" />
          </div>
        )}
        {isStarted && (
          <div className="flex justify-center items-center">
            <div className="flex flex-col gap-2 items-center justify-center">
              <Icon
                glyphName="Photo"
                className="text-blue text-[8rem] h-[8rem]"
              />
              <h2 className="text-black text-2xl">Awaiting Image</h2>
              <p className="text-text-tertiary">
                Waiting for image to be captured.
              </p>
              <ImageUploadForm
                onImageCaptured={onImageCaptured}
                onProductInfo={(productInfo) => setProductInfo(productInfo)}
              />
            </div>
          </div>
        )}
        {isIdle && (
          <div className="flex flex-col h-full w-full justify-center items-center">
            <Icon
              glyphName="Photo"
              className="text-blue text-[8rem] h-[8rem]"
            />
            <p className="text-blue">Product Image</p>
          </div>
        )}
      </div>
    </>
  );
};
