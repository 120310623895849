import React, { useContext } from "react";

import { Status, StatusDot } from "../components/fixtures";
import { Icon } from "../shared-components";

import { ProductAnalysisContext } from "./ProductAnalysisProvider";

export const ProductAnalysis = () => {
  const { productInfo } = useContext(ProductAnalysisContext);

  const [product] = productInfo?.products_found ?? [];

  console.log({ productInfo });

  return (
    <>
      <div className="product-analysis-column-header">
        <StatusDot status={Status.default} />
        <div className="uppercase">2. Analysis</div>
      </div>
      {product ? (
        <div className="p-4 flex flex-col gap-2">
          <h2 className="text-4xl">Product Details</h2>
          <dl>
            <dt className="text-text-tertiary mt-4">Product Name</dt>
            <dd className="text-2xl pb-4 border-b-2 border-b-[#DAE3F9] ">
              {product.name}
            </dd>
          </dl>
        </div>
      ) : (
        <div className="product-analysis-column-content">
          <div className="flex flex-col h-full w-full justify-center items-center">
            <Icon
              glyphName="Description"
              className="text-blue text-[8rem] h-[8rem]"
            />
            <p className="text-blue">Product Analysis</p>
          </div>
        </div>
      )}
    </>
  );
};
