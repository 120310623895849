import { useContext, useEffect, useRef, useState } from "react";

import { ProductAnalysisContext } from "../../product-analysis/ProductAnalysisProvider";
import { Icon } from "../../shared-components";

export const ImageUploadForm = ({ onImageCaptured, onProductInfo }) => {
  const { startAnalysis } = useContext(ProductAnalysisContext);

  const formSubmitRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("image", selectedFile);

    const response = await startAnalysis({ formData });

    onProductInfo(response);
  };

  useEffect(() => {
    if (selectedFile) {
      onImageCaptured(URL.createObjectURL(selectedFile));
      formSubmitRef.current.click();
    }
  }, [onImageCaptured, selectedFile]);

  return (
    <form onSubmit={onSubmit} className="contents">
      <label
        className="flex flex-row gap-2 border-2 p-2 border-blue-50 rounded-xl"
        htmlFor="imageCapture"
      >
        <Icon glyphName="photo_camera" />
        Capture Image
      </label>
      <input
        id="imageCapture"
        type="file"
        accept="image/*"
        className="opacity-0"
        onChange={handleFileChange}
      />
      <input ref={formSubmitRef} type="submit" className="hidden" />
    </form>
  );
};
