import React, { useContext } from "react";

import { Toolbar } from "../components/layout";
import { Button } from "../shared-components";

import { ProductAnalysisContext } from "./ProductAnalysisProvider";

export const ProductAnalysisToolbar = () => {
  const {
    setStarted,
    stopAnalysis,
    isIdle,
    isStarted,
    isFinished,
    isAnalysing,
    reset,
  } = useContext(ProductAnalysisContext);

  return (
    <Toolbar>
      <h2 className="text-4xl">Product Analysis</h2>
      <div className="flex flex-row">
        {isIdle && (
          <Button
            iconGlyph="play_circle"
            label="Start Analysis"
            onClick={() => setStarted()}
          />
        )}
        {isStarted && (
          <Button label="Stop Analysis" onClick={() => stopAnalysis()} />
        )}
        {isAnalysing && <Button label="Processing Started" disabled={true} />}
        {isFinished && (
          <Button
            iconGlyph="arrow_forward"
            label="Next Item"
            onClick={() => reset()}
          />
        )}
      </div>
    </Toolbar>
  );
};
