import { useMutation } from "react-query";
import axios from "axios";

export const useUpload = () => {
  return useMutation({
    mutationKey: ["upload"],
    mutationFn: async ({ formData }) => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT ? process.env.REACT_APP_API_ENDPOINT : ""}/api/upload/object-recognition-beauty-container`,
        formData
      );

      return response.data;
    },
  });
};
