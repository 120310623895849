import React from "react";
import classNames from "classnames";
import { Icon } from "../icon";

export const Button = ({
  dataTestId,
  disabled,
  badge,
  iconClassName,
  secondary,
  minimal,
  iconOutlined = false,
  iconGlyph,
  onClick,
  label,
  className,
  children,
}) => {
  const buttonClasses = classNames(
    "flex whitespace-nowrap relative items-center cursor-pointer rounded-lg px-large py-regular min-h-10 transition-colors",
    {
      "bg-default enabled:hover:bg-slate-50 border border-primary text-primary dark:bg-slate dark:border-dark-primary dark:enabled:hover:bg-black dark:enabled:text-white disabled:text-slate-400 disabled:border-disabled dark:disabled:border-slate-800":
        secondary,
      "bg-blue font-medium enabled:hover:bg-blue-800 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.08),_0px_-1px_1px_0px_rgba(0,0,0,0.16)_inset] min-h-[42px] text-white disabled:bg-disabled":
        !secondary,
      "pl-medium": iconGlyph,
      "text-primary border-0": minimal,
      "shadow-none hover:bg-inherit hover:cursor-not-allowed": disabled,
    },
    className,
  );

  const iconClasses = classNames(
    {
      "pr-regular": label?.length && !children,
      "text-primary dark:text-white": secondary || minimal,
      "text-white": !secondary,
      hidden: !iconGlyph,
      "opacity-25": disabled,
    },
    iconClassName,
  );

  return (
    <button
      data-testid={dataTestId}
      disabled={disabled}
      className={buttonClasses}
      onClick={onClick}
    >
      {Boolean(badge) && (
        <div
          className={`
          rounded-full 
          bg-red border-[1px] border-white 
          h-[12px] w-[12px]
          absolute -top-[4px] -right-[5px] 
          text-[8px] text-white
          `}
        >
          {badge}
        </div>
      )}
      {iconGlyph && (
        <Icon
          outlined={iconOutlined}
          glyphName={iconGlyph}
          className={iconClasses}
        />
      )}
      {label && <p className="text-base">{label}</p>}
      {children}
    </button>
  );
};
