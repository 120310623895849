import { createContext, useState } from "react";

import { useUpload } from "../api/useUpload";

export const ProductAnalysisContext = createContext({});

export const Status = {
  Idle: "Idle",
  Started: "Started",
  Analysing: "Analysing",
  Finished: "Finished",
};
export const ProductAnalysisProvider = ({ children }) => {
  const [status, setStatus] = useState(Status.Idle);
  const [productInfo, setProductInfo] = useState(null);

  const { mutateAsync: uploadImage } = useUpload();

  const setStarted = () => {
    setStatus(Status.Started);
  };

  const stopAnalysis = () => {
    setStatus(Status.Idle);
  };

  const startAnalysis = async ({ formData }) => {
    setStatus(Status.Analysing);
    try {
      return await uploadImage({ formData });
    } finally {
      setStatus(Status.Finished);
    }
  };

  const reset = () => {
    setStatus(Status.Idle);
    setProductInfo(null);
  };

  return (
    <ProductAnalysisContext.Provider
      value={{
        isIdle: status === Status.Idle,
        isStarted: status === Status.Started,
        isAnalysing: status === Status.Analysing,
        isFinished: status === Status.Finished,
        setStarted,
        stopAnalysis,
        productInfo,
        setProductInfo,
        startAnalysis,
        reset,
      }}
    >
      {children}
    </ProductAnalysisContext.Provider>
  );
};
