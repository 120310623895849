import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { ProductAnalysis } from "./product-analysis/ProductAnalysis";
import { ProductAnalysisProvider } from "./product-analysis/ProductAnalysisProvider";
import { ProductAnalysisToolbar } from "./product-analysis/ProductAnalysisToolbar";
import { ProductIdentification } from "./product-analysis/ProductIdentification";
import { ProductOutcome } from "./product-analysis/ProductOutcome";
import { MainNav } from "./shared-components";

import "./App.css";

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ProductAnalysisProvider>
        <div className="h-full flex flex-col">
          <div className="flex-initial">
            <MainNav />
          </div>
          <div className="flex-initial">
            <ProductAnalysisToolbar />
          </div>
          <div className="flex-auto">
            <div className="grid grid-cols-3 divide-x h-full">
              <div id="identification" className="product-analysis-column">
                <ProductIdentification />
              </div>
              <div id="analysis" className="product-analysis-column">
                <ProductAnalysis />
              </div>
              <div id="outcome" className="product-analysis-column">
                <ProductOutcome />
              </div>
            </div>
          </div>
        </div>
      </ProductAnalysisProvider>
    </QueryClientProvider>
  );
};

export default App;
