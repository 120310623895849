import { clsx } from "clsx";

export const Status = {
  default: "default",
  success: "success",
};

export const StatusDot = ({ status }) => {
  return (
    <div
      className={clsx("rounded-full w-[1rem] h-[1rem]", {
        "bg-[#00CFA8]": status === Status.success,
        "bg-[#173991]": status === Status.default,
      })}
    />
  );
};
